import React from 'react';
import styled from 'styled-components';
import {
  brandFonts,
  breakpointSizes,
  fluidFontSize,
  fontWeights,
} from '../styles';

const StyledBanner = styled.section`
  max-width: 1920px;
  margin: auto;
`;

const StyledHeading = styled.h2`
  font-family: ${brandFonts.tertiary};
  font-weight: ${fontWeights.bold};
  ${fluidFontSize(
    '20px',
    '50px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};
  text-align: center;
  margin: 120px 0;
`;

const Banner = ({ heading }) => (
  <StyledBanner>
    {heading && <StyledHeading>{'404 Error Page Not Found :('}</StyledHeading>}
  </StyledBanner>
);

export default Banner;
